import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ContentBlock, ContentState } from "draft-js";

export const variableDecorator = {
  strategy: (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "VARIABLE"
      );
    }, callback);
  },
  component: ({
    entityKey,
    children,
    contentState,
  }: {
    entityKey: string;
    children: Element;
    contentState: ContentState;
  }) => {
    const { url, value } = contentState.getEntity(entityKey).getData();
    // return <mark style={{ backgroundColor: "#c7cee1" }}>{children}</mark>;
    return (
      <Paper
        sx={{
          m: "2px",
          px: "4px",
          display: "inline-flex",
          backgroundColor: "#f0cca4",
        }}
      >
        <Typography
          variant="button"
          textAlign={"center"}
          fontSize={11}
          sx={{
            whiteSpace: "nowrap",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          {children}
        </Typography>
      </Paper>
    );
  },
};

import { getSelectedBlock } from "lib/draftjsUtil";
import { EditorState, Modifier } from "draft-js";

const VARIABLE_ENTITY = "VARIABLE";

export default function addVariable(
  editorState,
  onChange,
  separator,
  trigger,
  suggestion
) {
  const { value, url } = suggestion;
  const entityKey = editorState
    .getCurrentContent()
    .createEntity(VARIABLE_ENTITY, "IMMUTABLE", {
      text: `${value}`,
      value,
      url,
    })
    .getLastCreatedEntityKey();
  const selectedBlock = getSelectedBlock(editorState);
  const selectedBlockText = selectedBlock.getText();
  let focusOffset = editorState.getSelection().focusOffset;
  const mentionIndex =
    (selectedBlockText.lastIndexOf(separator, focusOffset) || 0) + 1;
  let spaceAlreadyPresent = false;
  if (selectedBlockText.length === mentionIndex + 1) {
    focusOffset = selectedBlockText.length;
  }
  if (selectedBlockText[focusOffset] === " ") {
    spaceAlreadyPresent = true;
  }
  let updatedSelection = editorState.getSelection().merge({
    anchorOffset: mentionIndex,
    focusOffset,
  });
  let newEditorState = EditorState.acceptSelection(
    editorState,
    updatedSelection
  );
  let contentState = Modifier.replaceText(
    newEditorState.getCurrentContent(),
    updatedSelection,
    `${value}`,
    newEditorState.getCurrentInlineStyle(),
    entityKey
  );
  newEditorState = EditorState.push(
    newEditorState,
    contentState,
    "insert-characters"
  );

  if (!spaceAlreadyPresent) {
    // insert a blank space after mention
    updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: mentionIndex + value.length,
      focusOffset: mentionIndex + value.length,
    });
    newEditorState = EditorState.acceptSelection(
      newEditorState,
      updatedSelection
    );
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      updatedSelection,
      " ",
      newEditorState.getCurrentInlineStyle(),
      undefined
    );
  }
  onChange(EditorState.push(newEditorState, contentState, "insert-characters"));
}
